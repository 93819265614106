import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Since `}<a parentName="p" {...{
        "href": "https://xappmedia.com/"
      }}>{`XAPPmedia`}</a>{` started in 2012, its mission has been clear: help enterprise media, brands and organizations deliver delightful experiences and drive engagement through voice. Through their One Voice AI™ tools and cloud services, they offer enterprises Voice and Conversational AI as a service to provide fast time-to-market and scalability to their clients. XAPPmedia has launched and currently manages over 1,200 voice apps on Alexa, Google Assistant, and Cortana for leading clients like National Geographic, E `}{`&`}{` J. Gallo Winery, Toyota, and more.`}</p>
    <p>{`XAPPmedia’s secret to a successful voice app lies in an end-to-end approach. “Launch is day one,” said Kirk Owen, VP of Business Development.`}</p>
    <p>{`According to Owen, voice is hard to design and it’s even more difficult to deploy. It’s not something that you can get right on the first try, so access to data is key to a successful, sustainable project. Additionally, XAPPmedia is expected to deliver meaningful insights and routinely report on the performance of their voice apps to clients, a task that hinges on their ability to analyze data.`}</p>
    <p>{`It’s also a task that XAPPmedia has entrusted to Dashbot. With its comprehensive list of reports, the team at XAPP has visibility in every conversation across various client accounts. They’re also able to deftly manage all the different accounts with Dashbot’s dashboard, all to ensure they’re delivering delight in a voice app every time.`}</p>
    <h3><strong parentName="h3">{`Best foot forward`}</strong></h3>
    <p>{`During the development stage, XAPPmedia starts running Dashbot’s analytics as soon as they have a Minimum Viable Product. The benefits of tracking conversation data even before the app launches are two-fold.`}</p>
    <p>{`It makes it possible for XAPPmedia to showcase usable prototypes that their clients can interact with straight away to better grasp what the actual user experience will be like. At the same time, it gives XAPPmedia a head start in the iterative improvement process of the conversation.`}</p>
    <p>{`“We’re tracking that data even before `}{`[`}{`the app`}{`]`}{` is live, so we can start tuning from there,” said Owen.`}</p>
    <h3><strong parentName="h3">{`Day one`}</strong></h3>
    <p>{`According to Owen, no matter how much hard work is put in the building phase of the voice app, it’s not going to be able to handle every situation the “real world” is going to throw at it. At launch, it is critical to quickly spot requests the app does not handle and improve response effectiveness as fast as possible. XAPPmedia uses Dashbot’s `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/tour/conversational-analytics/"
      }}><strong parentName="a">{`Unhandled Reports`}</strong></a>{` to catch all the utterances the app does not yet understand and feeds these utterances back to their NLP engine to retrain it. This has proven to be a massive time saver to XAPPmedia’s team.`}</p>
    <p>{`If the Unhandled Reports’ first level is to identify utterances that require a fix in the conversation, the next level is to pinpoint potential requests users would like to be able to achieve with the app. It is an opportunity to evolve their app in a direction that will increase user engagement and monetization. Such valuable, first-hand insights on user needs work to strengthen XAPPmedia’s relationship with their clients.`}</p>
    <h3><strong parentName="h3">{`A constant process`}</strong></h3>
    <p>{`Once the voice app is up and running, XAPPmedia uses Dashbot’s transcripts feature on a weekly or monthly basis, depending on the maturity of the voice app. Dashbot’s `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/tour/conversational-analytics/"
      }}><strong parentName="a">{`Transcripts Search`}</strong></a>{` allows them to retrace specific conversations flows and understand where the users get confused or frustrated.`}</p>
    <p>{`For example, one of the apps XAPPmedia developed allows users to adjust the voice speed to slow, medium, and fast. A great usability feature on paper, but it left users puzzled in practice. Using Dashbot’s transcripts report, the team realized that the voice app did not inform the user of the current setting when users triggered this intent, making it hard for the users to know which speed was actually the right one for them. Once the issue was identified, the conversation content was quickly changed and user experience improved for better engagement.`}</p>
    <p>{`But Owen doesn’t expect their clients to just take their word on it. In order to showcase definitive improvement, Dashbot’s `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/tour/behavior/"
      }}><strong parentName="a">{`Goals Report`}</strong></a>{` make it possible for XAPPmedia to measure the impact of content changes by tracking conversion over time.`}</p>
    <h3><strong parentName="h3">{`Strategic voice advisors`}</strong></h3>
    <p>{`Owen explained that because voice is new and exciting, clients are eager for insights for how their users interact with this new channel. To satisfy that curiosity, Owen meets with his clients for follow-ups on a weekly basis. He is able to set up and share with ease each client’s Dashbot dashboard with them.`}</p>
    <p>{`“We are strategic advisors to our clients about voice,” said Owen. “The dashboard is wonderful for keeping clients focused on the data that show success, so they don’t get lost in the metrics that have less value to their goals.”`}</p>
    <p>{`Head of Digital Strategy Mark Cerame insists on the importance of sharing meaningful insights and explaining to clients the “why” behind the data point.`}</p>
    <p>{`In traditional analytics, you’re able to see how long each conversation is and not much more. Without Dashbot, Cerame wouldn’t be able to determine what a successful conversation length would be and whether that’s a useful metric to the client at all. One could expect that a user who had a longer session really enjoyed their time in your voice app. Another possible reality is that this user experienced multiple fallback responses and got frustrated asking the same question over and over again.`}</p>
    <p>{`“There are instances where a skill has many types of experiences– long or short– that a user can have,” said Cerame. “Dashbot gives us a lot of visibility into what’s happening in the skill and it’s our job to figure out why things are happening.”`}</p>
    <p>{`Additionally, Owen says since Dashbot’s arsenal of reports are so complete and thorough, he hasn’t come across a data point he couldn’t analyze. He also stressed that if he was in a situation where he needed additional help, he knows where to find it.`}</p>
    <p>{`“I know the team at Dashbot and I know that I’m never far away from getting an answer,” he said. “ I get great service from y’all and I would never feel intimidated in any way to reach out as a customer.”`}</p>
    <h2>{`About Dashbot`}</h2>
    <p><em parentName="p"><a parentName="em" {...{
          "href": "https://www.dashbot.io/"
        }}>{`Dashbot`}</a>{` is a conversational analytics platform that enables enterprises and developers to understand user behaviors, optimize response effectiveness, and increase user satisfaction through actionable data and tools.`}</em></p>
    <p><em parentName="p">{`In addition to traditional analytics like engagement and retention, we provide chatbot specific metrics including NLP response effectiveness, sentiment analysis, conversational analytics, and the full chat session transcripts.`}</em></p>
    <p><em parentName="p">{`We also have tools to take action on the data, like our live person take over of chat sessions and broadcast messaging for re-engagement.`}</em></p>
    <p><em parentName="p">{`We support Alexa, Google Home, Facebook Messenger, Slack, Twitter, Kik, SMS, web chat, and any other conversational interface.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales?ref="
      }}>{`Contact us for a demo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      